import React from 'react';
import './ManualOrderDetailsComponent.css';
import {
    Text,
    Grid,
    Col,
    Title,
    TextInput,
  } from "@tremor/react";
import { FullOrder } from '../../../interfaces/OrderInterfaces';

export default function ManualOrderDetailsComponent(
    {order, setOrder}:{order:FullOrder, setOrder: Function}
  ) {

  return (
    <div>
        <Title className='mt-6'>Dados gerais</Title>
        <Grid numCols={2} className="gap-2">
          <Col numColSpan={1}>
            <Text>Número da nota</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.receiptNumber ?? ""}
              onChange={(e) => {setOrder({...order, receiptNumber: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Quantidade</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.amount ?? ""}
              onChange={(e) => {setOrder({...order, amount: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Altura (cm)</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.height ?? ""}
              onChange={(e) => {setOrder({...order, height: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Largura (cm)</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.width ?? ""}
              onChange={(e) => {setOrder({...order, width: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Comprimento (cm)</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.length ?? ""}
              onChange={(e) => {setOrder({...order, length: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Peso (g)</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.weigth ?? ""}
              onChange={(e) => {setOrder({...order, weigth: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Valor da mercadoria</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.value ?? ""}
              onChange={(e) => {setOrder({...order, value: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Descrição do produto</Text>
            <TextInput
              className='max-w-md'
              defaultValue={order?.product ?? ""}
              onChange={(e) => {setOrder({...order, product: e.target.value})}}
            />
          </Col>
        </Grid>
    </div>
  );
}