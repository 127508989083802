import React, { useState, useEffect } from 'react';
import './CorreiosSearchComponent.css';
import {
  Button,
  Dropdown,
  DropdownItem,
  Grid,
  Col,
  Title,
  TextInput,
  DateRangePicker,
  Card,
  Divider,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableHead,
  DateRangePickerValue
  } from '@tremor/react';
import { Link } from "react-router-dom";
import { ptBR } from "date-fns/locale";
import { ShortOrder } from '../../interfaces/OrderInterfaces';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function CorreiosSearchComponent(
  {selectedOrders, setSelectedOrders}:{selectedOrders:string[], setSelectedOrders:Function}
) {
  const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
  const limit = 100;
  const now = new Date();
  const [cookies] = useCookies(['mazelog-user']);
  const orgData = cookies["mazelog-user"]?.organization_data;
  const id_org = orgData?.id_org;
  const correios_flag = orgData?.correios;
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;

  const [requested, setRequested] = useState<boolean>(false);
  const [selectedReceiver, setSelectedReceiver] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedNumber, setSelectedNumber] = useState<string>('');
  const [selectedPrepostID, setSelectedPrepostID] = useState<string>('');
  const [selectedTrackingCode, setSelectedTrackingCode] = useState<string>('');
  const [searchedOrders, setSearchedOrders] = useState<ShortOrder[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerValue>([
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    now
  ]);
  const [expansions, setExpansions] = useState<number>(0);
  const [fileDownloadLoading, setFileDownloadLoading] = useState<boolean>(false);

  const selectAllHandler = () => {
    if (searchedOrders.length === selectedOrders.length) {
        setSelectedOrders([])
    } else {
        const orderNumbers = searchedOrders.map(({orderNumber}) => orderNumber);
        setSelectedOrders(orderNumbers);
    }
  }

  const selectOneHandler = (orderNumber: string) => {
    if (selectedOrders.includes(orderNumber)) {
        setSelectedOrders(selectedOrders.filter((value)=>{
            return value !== orderNumber
        }))
    } else {
        const updatedSelectedOrders = selectedOrders.concat(orderNumber);
        setSelectedOrders(updatedSelectedOrders);
    }
  }

  const expandSearchResults = () => {
    setExpansions(expansions+1);
    setRequested(false);
  }

  const truncateString = (text:string, maxLength: number):string => {
    if (text.length <= maxLength) {
        return text
    } else {
        return text.substring(0, maxLength) + '...'
    }
  }

  const handleSubmit = () => {
    let URL = `${baseURL}/correios/query?emission_start_date=${selectedDateRange[0]?.toISOString()}&emission_end_date=${selectedDateRange[1]?.toISOString()}`
    if(selectedNumber !== ''){URL += `&order_number=${selectedNumber}`}
    if(selectedStatus !== 'all'){URL += `&status=${selectedStatus}`}
    if(selectedPrepostID !== ''){URL += `&prepost_id=${selectedPrepostID}`}
    if(selectedTrackingCode !== ''){URL += `&tracking_code=${selectedTrackingCode}`}
    if(orgData?.profile === 'CLIENT') {URL += `&sender_id_org=${id_org}`}
    if(selectedReceiver !== ''){URL += `&receiver_document=${selectedReceiver.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,"")}`}
    if(expansions > 0){URL+= `&expansions=${expansions}`}
    if(['CORP','CLIENT'].includes(orgData?.profile) && correios_flag){
      axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
      .then((res)=>{
        console.log(res);
        setSearchedOrders(res.data.results);
      })
      .catch((err)=>{
        console.log(err);
      })
    }
  }

  const buildOrderCsv = async () : Promise<string> => {
    let returnData = "";
    if(['CORP','CLIENT'].includes(orgData?.profile) && correios_flag) {
      let URL = `${baseURL}/correios/query/file?emission_start_date=${selectedDateRange[0]?.toISOString()}&emission_end_date=${selectedDateRange[1]?.toISOString()}`
      if(selectedNumber !== ''){URL += `&order_number=${selectedNumber}`}
      if(selectedStatus !== 'all'){URL += `&status=${selectedStatus}`}
      if(selectedPrepostID !== ''){URL += `&prepost_id=${selectedPrepostID}`}
      if(selectedTrackingCode !== ''){URL += `&tracking_code=${selectedTrackingCode}`}
      if(orgData?.profile === 'CLIENT') {URL += `&sender_id_org=${id_org}`}
      if(selectedReceiver !== ''){URL += `&receiver_document=${selectedReceiver.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,"")}`}
      const resp = await axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
      returnData = resp.data
    }
    return returnData;
  }

  const downloadCsv = async () => {
    setFileDownloadLoading(true);
    const csvData = await buildOrderCsv();
    const a = document.createElement("a");
    a.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData));
    a.setAttribute("download", "download.csv");
    a.click();
    setFileDownloadLoading(false);
  }

  const convertDate = (date:Date) => {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() - 3);
    let newDateStr = newDate.toLocaleString('en-GB').substring(0, 10);
    return newDateStr
  }

  useEffect(()=>{
    if (!requested){
      handleSubmit();
      setRequested(true);
    }
  },[requested])

  return (
    <div>
      <div className='w-full'>
        <Card>
          <Grid numCols={2} className="gap-2">
            <Col numColSpan={1}>
              <Title>ID do pedido</Title>
              <TextInput className='max-w-md' onChange={(e) => setSelectedNumber(e.target.value)}/>
            </Col>
            <Col numColSpan={1}>
              <Title>Status</Title>
              <Dropdown
                  className='max-w-md'
                  defaultValue="all"
                  onValueChange={ (value) => setSelectedStatus(value) }
              >
                  <DropdownItem value="all" text="Todos os status" />
                  <DropdownItem value="preposted" text="Pré-postado" />
                  <DropdownItem value="cancelled" text="Pré-postagem cancelada" />
                  <DropdownItem value="posted" text="Objeto postado" />
                  <DropdownItem value="transit" text="Objeto em trânsito - por favor aguarde" />
                  <DropdownItem value="not_delivered" text="Objeto não entregue - empresa sem expediente" />
                  <DropdownItem value="en_route" text="Objeto saiu para entrega ao destinatário" />
                  <DropdownItem value="delivered" text="Objeto entregue ao destinatário" />
              </Dropdown>
            </Col>
            <Col numColSpan={1}>
              <Title>CNPJ/CPF destinatário</Title>
              <TextInput placeholder="00000000000000" className='max-w-md' onChange={(e) => setSelectedReceiver(e.target.value)}/>                  
            </Col>
            <Col numColSpan={1}>
              <Title>ID prepostagem</Title>
              <TextInput className='max-w-md' onChange={(e) => setSelectedPrepostID(e.target.value)}/>                  
            </Col>
            <Col numColSpan={1}>
              <Title>Código de rastreio</Title>
              <TextInput className='max-w-md' onChange={(e) => setSelectedTrackingCode(e.target.value)}/>                  
            </Col>
            <Col numColSpan={1}>
              <Title>Data de criação</Title>
              <DateRangePicker
                className="max-w-md"
                value={selectedDateRange}
                onValueChange={setSelectedDateRange}
                locale={ptBR}
                dropdownPlaceholder="Selecionar"
                enableYearPagination={true}
              />
            </Col>
            <Col numColSpan={1} className='DownloadCSVContainer'>
              <Button
                className='FilterButton'
                onClick={handleSubmit}
                disabled={!(
                  ['CORP','CLIENT'].includes(orgData?.profile)
                  && correios_flag
                )}
              >
                Filtrar
              </Button>
              <Button
                className='DownloadCSVButton'
                onClick={downloadCsv}
                disabled={!(
                  ['CORP','CLIENT'].includes(orgData?.profile)
                  && correios_flag
                )}
                loading={fileDownloadLoading}
              >
                Download
              </Button>
              <Link to="/orders">
                <Button
                  className='OpenStickerButton'
                  disabled={!(
                    ['CORP','CLIENT'].includes(orgData?.profile)
                    && correios_flag
                  )}
                >
                  Abrir etiquetas
                </Button>
              </Link>
            </Col>
          </Grid>
          <Divider className='h-1'/>
          <div>
            <Table>
              <TableHead>
                  <TableRow>
                      <TableHeaderCell>
                          <input
                              type='checkbox'
                              checked={searchedOrders.length === selectedOrders.length}
                              onChange={selectAllHandler}
                              className='OrderCheckBox'
                          />
                      </TableHeaderCell>
                      <TableHeaderCell className="text-left">ID do pedido</TableHeaderCell>
                      <TableHeaderCell className="text-left">Número da nota</TableHeaderCell>
                      <TableHeaderCell className="text-left">Data de criação</TableHeaderCell>
                      <TableHeaderCell className="text-left">Status</TableHeaderCell>
                      <TableHeaderCell className="text-left">Destinatário</TableHeaderCell>
                      <TableHeaderCell className="text-left">CEP Destinatário</TableHeaderCell>
                  </TableRow>
              </TableHead>

              <TableBody>
                  { searchedOrders
                      .map((order) => (
                          <TableRow key={ order.orderNumber }>
                              <TableCell className="TableField">
                                  <input
                                      type='checkbox'
                                      checked={selectedOrders.includes(order.orderNumber)}
                                      onChange={() => selectOneHandler(order.orderNumber)}
                                  />
                              </TableCell>
                              <TableCell className="TableField">{ order.orderNumber }</TableCell>
                              <TableCell className="TableField">{ order.receiptNumber }</TableCell>
                              <TableCell className="TableField">{ convertDate(order.creationDate) }</TableCell>
                              <TableCell className="TableField">{ order.status }</TableCell>
                              <TableCell className="TableField">{ truncateString(order.receiver, 25) }</TableCell>
                              <TableCell className="TableField">{ order.receiverZIP }</TableCell>
                              <Link to={`/order/${order.orderNumber}`}>
                                  <Button className='OpenOrderBtn' icon={ArrowUpRightIcon} />
                              </Link>
                          </TableRow>
                      )) }
                </TableBody>
            </Table>
          </div>
          {searchedOrders.length === limit*(expansions+1) ?
          <div>
            <Button onClick={expandSearchResults} className='FilterButton'>
              Carregar mais
            </Button>
          </div> : ""}
        </Card>
      </div>
    </div>
  );
}