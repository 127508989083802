import React, { useEffect, useState } from 'react';
import './OrderDetailPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { FullOrder } from '../interfaces/OrderInterfaces';
import OrderComponent from '../components/OrderComponent';
import { Button, Flex, Metric, Card } from '@tremor/react';
import  PDFStickerComponent from '../components/stickerComponents/MazelogStickerComponent';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Drivers } from '../interfaces/UserInterfaces';
import OrderHistoryComponent from '../components/OrderHistoryComponent';

export default function OrderDetailPage(
        {
            drivers,
            setShowCard,
            setManualBaseOrder,
            setReverseLogistic
        }:
        {
            drivers:Drivers,
            setShowCard:Function,
            setManualBaseOrder:Function,
            setReverseLogistic:Function
        }
    ) {
    const [cookies] = useCookies(['mazelog-user']);
    const productFlag = cookies['mazelog-user']?.organization_data?.product_flag;
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    let { id } = useParams();
    const [order, setOrder] = useState<FullOrder>();
    const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
    const [correiosOrders, setCorreiosOrders] = useState<boolean>(false);
    const [reverseLogisticOrder, setReverseLogisticOrder] = useState<boolean>(false);
    const [stickerButtonDisabled, setStickerButtonDisabled] = useState<boolean>(true);
    const [declarationButtonDisabled, setDeclaraionButtonDisabled] = useState<boolean>(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState<boolean>(false);
    const [correiosTagDownloading, setCorreiosTagDownloading] = useState<boolean>(false);

    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(`${baseURL}/order/${id}`,
            {headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrder(res.data);
                setOrderUpdated(false);
            })
            .catch((err) => {
                console.log(err);
                navigate(`/`);
            })
    },[id,orderUpdated])

    useEffect(()=>{
        if (order?.transporter === "correios") {
            setCorreiosOrders(true);
        }
        setReverseLogisticOrder(!!order?.reverseLogistic);
    },[order])

    const cancelOrder = async () => {
        if (window.confirm("Deseja mesmo cancelar este pedido?")){
            axios.delete(`${baseURL}/order/${id}`,{headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrderUpdated(true);
            })
            .catch((err) => {
                console.log(err);
                if (axios.isAxiosError(err) && err.response) {
                    alert(err.response?.data?.detail);
                }
            })
        }
    }

    const downloadCorreiosStickerTag = () => {
        setCorreiosTagDownloading(true);
        axios.post(`${baseURL}/correios/tag`,
                    {
                    data: [id],
                    "product_flag": productFlag,
                    },
                    {
                        headers: {
                            "Authorization": oauth,
                        },
                        responseType: 'blob'
                    })
            .then((response) => new Blob([response.data]))
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `etiqueta_correios_${id}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                })
            .catch((err) =>
                alert("Etiqueta dos correios ainda está sendo gerada, tente mais tarde.")
            )
            .finally(() => setCorreiosTagDownloading(false))
    }

    const reverseLogisticHandler = () => {
        setShowCard("1");
        setManualBaseOrder(order);
        setReverseLogistic(true);
        navigate(`/create`);
    }

    useEffect(() => {
        //download sticker
        setStickerButtonDisabled(!(
            order?.correios &&
            order?.correios?.statusPostagem[order?.correios?.statusPostagem.length - 1].description === "Pré-postado" &&
            (new Date(order?.correios?.prazoPostagem) > new Date())
        ));

        //declaration
        setDeclaraionButtonDisabled(false);

        //cancel
        setCancelButtonDisabled(Boolean(order) &&
            Boolean(['delivered', 'cancelled'].includes(order!.events[order!.events.length - 1].type))
        );
    }, [order])

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            <div>
                <Flex justifyContent='end'>
                    {order ? 
                    (
                    <Flex justifyContent='end'>
                        {!order?.reverseLogistic ?
                        <div>
                            <Button
                                className='DownloadBtn'
                                onClick={reverseLogisticHandler}
                            >
                            Criar etiqueta logística reversa
                            </Button>
                        </div> : ""}
                        {order.transporter !== 'correios' ?
                        <div>
                            <Button className='DownloadBtn'>
                                <PDFDownloadLink
                                    document={<PDFStickerComponent orderList={[order]} productFlag={productFlag} />}
                                    fileName={`${order?.orderNumber}.pdf`}
                                >
                                Baixar etiqueta Mazelog
                                </PDFDownloadLink>
                            </Button>
                        </div>
                        : ""}
                        {(correiosOrders && !reverseLogisticOrder) ?
                            <div>
                                <Button
                                    className='DownloadBtn'
                                    disabled={stickerButtonDisabled}
                                    onClick={downloadCorreiosStickerTag}
                                    loading={correiosTagDownloading}
                                >Baixar etiqueta Correios</Button>
                                <Link to={`/orders/declaration/${order?.orderNumber}`} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className='DownloadBtn'
                                        disabled={declarationButtonDisabled}
                                    >Baixar declaração de conteúdo</Button>
                                </Link>
                            </div> : ""}
                        <Link to={`/occurrence/${order.orderNumber}`}>
                            <Button className='DownloadBtn'>Abrir ocorrência</Button>
                        </Link>
                        <Button
                            className='DownloadBtn'
                            onClick={cancelOrder}
                            disabled={cancelButtonDisabled}
                        >Cancelar pedido</Button>
                    </Flex>) :
                    (<div>
                    <Button className='DownloadBtn' disabled={true}>Cancelar pedido</Button>
                    <Button className='DownloadBtn' disabled={true}>Abrir ocorrência</Button>
                    </div>)}
                </Flex>
            </div>
            <div className="DashboardViews">
                <OrderComponent order={order} />
            </div>
            <div>
                <OrderHistoryComponent order={order} drivers={drivers} />
            </div>
            <br/>
            <div>
                {(order)&&(order.transporter !== 'correios') ? 
                (
                <div>
                    <Card>
                        <Metric>Etiqueta</Metric>
                        <PDFViewer className='PDFCard' showToolbar={false}>
                            <PDFStickerComponent orderList={[order]} productFlag={productFlag} />
                        </PDFViewer>
                    </Card>
                </div>
                ) :
                ('')}
            </div>
        </div>
    </main>
  );
}