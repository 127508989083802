import React, { useState, useEffect } from 'react';
import './ManualOrderComponent.css';
import {
  Button,
  Text,
  Title,
  TextInput,
  Dropdown,
  DropdownItem,
  Toggle,
  ToggleItem
} from "@tremor/react";
import { FullOrder } from '../../interfaces/OrderInterfaces';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ManualOrderRecepientComponent from './manualOrderSubComponents/ManualOrderRecepientComponent';
import ManualOrderDetailsComponent from './manualOrderSubComponents/ManualOrderDetailsComponent';

export default function ManualOrderComponent(
  {
    manualBaseOrder,
    setCreatedOrders,
    setSelectedTransporter,
    selectedTransporter,
    reverseLogistic,
    setReverseLogistic,
  }:
  {
    manualBaseOrder:FullOrder|undefined,
    setCreatedOrders:Function,
    setSelectedTransporter:Function,
    selectedTransporter:string,
    reverseLogistic: boolean,
    setReverseLogistic: Function,
  }
  ) {
  const [cookies] = useCookies(['mazelog-user']);
  const organizationData = cookies['mazelog-user']?.organization_data;
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;
  
  let baseOrder = {
    orderNumber: "",
    status: "",
    receiptNumber: "0000000",
    sender: organizationData?.name ?? "",
    senderDocument: organizationData?.cnpj ?? "",
    senderTelephone: organizationData?.telephone ?? "",
    senderZIP: organizationData?.zip ?? "",
    senderStreet: organizationData?.street ?? "",
    senderStreetNumber: organizationData?.street_number ?? "",
    senderAddressNumber: (organizationData?.address_number ?? ""),
    senderNeighborhood: organizationData?.neighborhood,
    senderCity: organizationData?.city ?? "",
    senderState: organizationData?.state ?? "",
    senderObservation: "",
    receiver: "",
    receiverDocument: "",
    receiverTelephone: "",
    receiverEmail: "",
    receiverZIP: "",
    receiverStreet: "",
    receiverStreetNumber: "",
    receiverAddressNumber: "",
    receiverNeighborhood: "",
    receiverCity: "",
    receiverState: "SP",
    receiverObservation: "",
    product: "",
    amount: "",
    height: "",
    width: "",
    length: "",
    weigth: "",
    value: "",
    transporter: "",
    date: new Date(),
    events: [{type:'created', date: new Date().toISOString()}],
    collecterName: '',
    collecterDocument: '',
    observation: '',
    reverseLogistic: false,
  }

  const [requestProcessing, setRequestProcessing] = useState(false);
  const [order, setOrder] = useState<FullOrder>(!manualBaseOrder ?
    baseOrder : manualBaseOrder);
  const [reverseOrder, setReverseOrder] = useState<FullOrder>(baseOrder)
  const [
    selectedCorreiosService,
    setSelectedCorreiosService
  ] = useState<string>("SEDEX CONTRATO AG");
  
  const regexTreatField = (field: string|null) => {
    if (!!field) {
      return field.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,"")
    } else {
      return ""
    }
  }

  const treatInputFormat = (order: FullOrder) => {
    let treated_order = {...order};

    treated_order = {...treated_order, senderDocument: regexTreatField(treated_order.senderDocument)}
    treated_order = {...treated_order, senderTelephone: regexTreatField(treated_order.senderTelephone)}
    treated_order = {...treated_order, senderZIP: regexTreatField(treated_order.senderZIP)}
    treated_order = {...treated_order, receiverDocument: regexTreatField(treated_order.receiverDocument)}
    treated_order = {...treated_order, receiverTelephone: regexTreatField(treated_order.receiverTelephone)}
    treated_order = {...treated_order, receiverZIP: regexTreatField(treated_order.receiverZIP)}

    return treated_order
  }

  const reverseLogisticSwapFields = () => {
    setReverseOrder({
      ...order,
      sender: order?.receiver,
      receiver: order?.sender,
      senderDocument: order?.receiverDocument,
      receiverDocument: order?.senderDocument,
      senderTelephone: order?.receiverTelephone,
      receiverTelephone: order?.senderTelephone,
      receiverEmail: "",
      senderZIP: order?.receiverZIP,
      receiverZIP: order?.senderZIP,
      senderStreet: order?.receiverStreet,
      receiverStreet: order?.senderStreet,
      senderStreetNumber: order?.receiverStreetNumber,
      receiverStreetNumber: order?.senderStreetNumber,
      senderAddressNumber: order?.receiverAddressNumber,
      receiverAddressNumber: order?.senderAddressNumber,
      senderCity: order?.receiverCity,
      receiverCity: order?.senderCity,
      senderNeighborhood: order?.receiverNeighborhood,
      receiverNeighborhood: order?.senderNeighborhood,
      senderState: order?.receiverState,
      receiverState: order?.senderState,
      senderObservation: order?.receiverObservation,
      receiverObservation: order?.senderObservation,
    })
  }

  useEffect(() => {
    reverseLogisticSwapFields();
  }, [order, reverseLogistic])

  const navigate = useNavigate();
  const createOrderManually = () => {
    setRequestProcessing(true);
    console.log(order);
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
    axios.post(`${baseURL}/order/save/manual`,
                treatInputFormat(!reverseLogistic ? order : reverseOrder),
                {headers: {
                  "Content-Type": "application/json",
                  "Authorization": oauth,
                  "modal": selectedTransporter,
                  "service": selectedCorreiosService,
                  "reverse-logistic": reverseLogistic,
                }})
    .then((res) => {
      if (reverseLogistic && selectedTransporter === "correios") {
        navigate(`/order/${res.data.id}`);
      } else {
        setCreatedOrders([res.data.id]);
        navigate('/orders');
      }
    })
    .catch((err)=>{
      alert('Erro! '+err.response?.data?.detail);
      setRequestProcessing(false);
    });
  }

  return (
    <div>
      <Title className='mt-6'>Dados envio</Title>
        <div>
          <Text className='UploadTitle'>Selecionar forma de envio</Text>
          <div>
            <Toggle
              defaultValue={!reverseLogistic ? "1" : "2"}
              onValueChange={(value) => setReverseLogistic(value === "2")}
              color='purple'
            >
              <ToggleItem value="1" text="Logistica normal" />
              <ToggleItem value="2" text="Logística reversa" />
            </Toggle>
            <br/>
            <br/>
          </div>
          <Dropdown
              className='max-w-md'
              defaultValue="mazelog"
              onValueChange={ (value) => setSelectedTransporter(value) }
          >
            <DropdownItem value="mazelog" text="Mazelog" />
            <DropdownItem value="correios" text="Correios" />
          </Dropdown>
         </div>
         <br/>
         {selectedTransporter === 'correios' ? (
          <div>
            <Text className='UploadTitle'>
              Digite nome do serviço correios (Ex: SEDEX CONTRATO AG)
            </Text>
            <TextInput
              className='max-w-md'
              onChange={(e) => {setSelectedCorreiosService(e.target.value)}}
            />
            <br/>
          </div>
         ) : ("")}

        <ManualOrderRecepientComponent
          isSender={true}
          order={!reverseLogistic ? order : reverseOrder}
          setOrder={!reverseLogistic ? setOrder : setReverseOrder}
          reverseLogistic={reverseLogistic}
        />
        <br/>
        <ManualOrderRecepientComponent
          isSender={false}
          order={!reverseLogistic ? order : reverseOrder}
          setOrder={!reverseLogistic ? setOrder : setReverseOrder}
          reverseLogistic={reverseLogistic}
        />

        <ManualOrderDetailsComponent
          order={!reverseLogistic ? order : reverseOrder}
          setOrder={!reverseLogistic ? setOrder : setReverseOrder}  
        />
        <br/>
        <Button
          loading={requestProcessing}
          onClick={createOrderManually}
          className='CreateOrderBtn'
        > Criar </Button>
    </div>
  );
}